/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}

@keyframes slide-from-top {
	from {
		transform: translateY(-100%);
	} to {
		transform: translateY(0);
	}
}
*/
.infinity {
  overflow: auto;
  margin: 0 0 2rem;
}
.infinity[data-infinity-initialised] {
  overflow: hidden;
}
.infinity__carousel {
  display: flex;
  margin: 0 -15px;
  padding: 0;
  list-style-type: none;
}
.infinity__item {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
}
@media screen and (min-width: 43.75rem) {
  .infinity__item {
    width: 50%;
  }
}
@media screen and (min-width: 75rem) {
  .infinity__item {
    width: 33.3333333333%;
  }
}
.infinity__card {
  background: silver;
  padding: 20px;
  flex-grow: 1;
}
.infinity__card p {
  font-size: 2rem;
}

.infinity-indicator {
  display: inline-block;
  background-color: blue;
  width: 10px;
  height: 10px;
  border-radius: 500px;
}
.infinity-indicator--current {
  background-color: red;
}

.hero {
  padding: clamp(30px, 10vw, 150px) 0;
  background: #1d2337 no-repeat 0 center/cover;
  background-image: url("/assets/images/hero.jpg");
  background-image: image-set(url("/assets/images/hero.avif") type("image/avif"), url("/assets/images/hero.webp") type("image/webp"), url("/assets/images/hero.jpg") type("image/jpeg"));
}
.hero__text {
  margin: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: clamp(2.5rem, 10vw, 5rem);
  font-weight: 800;
  line-height: 1.125;
}
.hero__line {
  display: block;
}
.hero__line::first-letter {
  color: #84c450;
  text-transform: uppercase;
}

.help {
  background: #1d2337 url("/assets/images/bg-tech-inverted.svg") no-repeat -20% 50%/cover;
  color: white;
}
.help::after {
  content: "";
  display: block;
  height: 30px;
  background: #84c450 linear-gradient(to right, #84c450, #1b5962);
}
.help__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: clamp(30px, 5vw, 125px);
  padding-top: clamp(40px, 10vw, 130px);
  padding-bottom: clamp(40px, 10vw, 130px);
}
.help__heading {
  color: inherit;
  margin: 0;
  font-size: clamp(21px, 5vw, 50px);
  text-align: center;
}
.help__link, .help__link:link, .help__link:visited {
  display: inline-block;
  background-color: transparent;
  color: #84c450;
  border: 1px #84c450 solid;
  font-size: 1.25rem;
  padding: 19px 40px;
  text-decoration: none;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;
}
.help__link:hover, .help__link:focus, .help__link:active {
  background-color: #84c450;
  color: #1d2337;
}

.partners {
  border-top: 1px #d7d7d7 solid;
  padding: 4.625rem 0;
}
.partners__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: clamp(1.25rem, 4vw, 2.5rem) clamp(2.5rem, 5vw, 6.25rem);
}
.partners__link, .partners__link:link, .partners__link:visited {
  transition: filter 0.1s ease-out;
  filter: grayscale(1);
  text-decoration: none;
}
.partners__link:hover, .partners__link:focus, .partners__link:active {
  filter: grayscale(0);
}

.intro {
  overflow: hidden;
}
.intro__content {
  display: grid;
  grid-gap: clamp(3.125rem, 10vw, 7.5rem);
  gap: clamp(3.125rem, 10vw, 7.5rem);
}
@media screen and (min-width: 50rem) {
  .intro__content {
    grid-template-columns: minmax(auto, 43.75rem) 1fr;
  }
}
.intro__text {
  padding: clamp(2.5rem, 5vw, 4.375rem) 0;
}
.intro__text .text-module {
  font-size: 1.25rem;
  line-height: 1.7;
}
.intro__text .text-module p {
  font-size: inherit;
  line-height: inherit;
}
.intro__numbers {
  align-self: end;
}
.intro__stats {
  background-color: #e1e3e3;
  padding: 50px 0 40px 60px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11.25rem, 1fr));
  grid-gap: 30px;
  gap: 30px;
}
.intro__stats::before {
  content: "";
  position: absolute;
  width: 999em;
  left: 100%;
  top: 0;
  bottom: 0;
  background-color: #e1e3e3;
}
@media screen and (max-width: 49.9375rem) {
  .intro__stats::after {
    content: "";
    position: absolute;
    width: 999em;
    right: 100%;
    top: 0;
    bottom: 0;
    background-color: #e1e3e3;
  }
}
@media screen and (min-width: 87.5rem) {
  .intro__stats {
    padding-top: 80px;
    padding-left: 150px;
  }
}
.intro__stats p {
  margin: 0;
}
.intro__stats p strong {
  font-size: 3.125rem;
  font-weight: 800;
  color: #1d2337;
  display: block;
  line-height: 1;
  margin-bottom: 10px;
}