@use "sass:math";

/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars",
	"abstracts/animations";

//@import "components/blender";

.infinity {
	overflow: auto;
	margin: 0 0 2rem;

	&[data-infinity-initialised] {
		overflow: hidden;
	}

	&__carousel {
		display: flex;
		margin: 0 -15px;
		padding: 0;
		list-style-type: none;
	}

	&__item {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 15px;

		@include min(700px) {
			width: 50%;
		}

		@include min(1200px) {
			width: math.div(100%, 3);
		}
	}

	&__card {
		background: silver;
		padding: 20px;
		flex-grow: 1;

		p {
			font-size: 2rem;
		}
	}
}

.infinity-indicator {
	display: inline-block;
	background-color: blue;
	width: 10px;
	height: 10px;
	border-radius: 500px;

	&--current {
		background-color: red;
	}
}

// .homepage {
// 	padding-bottom: 75px;
// }

.hero {
	padding: clamp(30px, 10vw, 150px) 0;
	background: $color-brand no-repeat 0 center / cover;
	@include image-set(
		"#{$imagePath}hero.jpg",
		url("#{$imagePath}hero.avif") type("image/avif"),
		url("#{$imagePath}hero.webp") type("image/webp"),
		url("#{$imagePath}hero.jpg") type("image/jpeg")
	);

	&__text {
		margin: 0;
		color: white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		font-size: clamp(rem(40px), 10vw, rem(80px));
		font-weight: 800;
		line-height: math.div(90, 80);
	}
	&__line {
		display: block;

		&::first-letter {
			color: $color-secondary;
			text-transform: uppercase;
		}
	}
}

.help {
	background: $color-brand url("/assets/images/bg-tech-inverted.svg") no-repeat -20%
		50% / cover;
	//background-blend-mode: color-burn;
	color: white;

	&::after {
		content: "";
		display: block;
		height: 30px;
		background: $color-secondary $gradient-secondary;
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: clamp(30px, 5vw, 125px);
		padding-top: clamp(40px, 10vw, 130px);
		padding-bottom: clamp(40px, 10vw, 130px);
	}

	&__heading {
		color: inherit;
		margin: 0;
		font-size: clamp(21px, 5vw, 50px);
		text-align: center;
	}
	&__link {
		@include link(&) {
			display: inline-block;
			background-color: transparent;
			color: $color-secondary;
			border: 1px $color-secondary solid;
			font-size: rem(20px);
			padding: 19px 40px;
			text-decoration: none;
			transition: background-color 0.1s ease-out, color 0.1s ease-out;
		}
		@include link-over(&) {
			background-color: $color-secondary;
			color: $color-brand;
		}
	}
}

.partners {
	border-top: 1px #d7d7d7 solid;
	padding: rem(74px) 0;

	&__list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		flex-wrap: wrap;
		gap: clamp(rem(20px), 4vw, rem(40px)) clamp(rem(40px), 5vw, rem(100px));
	}

	&__link {
		@include link(&) {
			transition: filter 0.1s ease-out;
			filter: grayscale(1);
			text-decoration: none;
		}
		@include link-over(&) {
			filter: grayscale(0);
		}
	}
}

.intro {
	overflow: hidden;

	&__content {
		display: grid;
		grid-gap: clamp(rem(50px), 10vw, rem(120px));
		gap: clamp(rem(50px), 10vw, rem(120px));

		@include min(800px) {
			grid-template-columns: minmax(auto, rem(700px)) 1fr;
		}
	}

	&__text {
		padding: clamp(rem(40px), 5vw, rem(70px)) 0;

		.text-module {
			font-size: rem(20px);
			line-height: math.div(34, 20);

			p {
				font-size: inherit;
				line-height: inherit;
			}
		}
	}

	&__numbers {
		align-self: end;
	}

	&__stats {
		background-color: $color-neutral;
		padding: 50px 0 40px 60px;
		position: relative;
		@include pad($color-neutral, right);
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(rem(180px), 1fr));
		grid-gap: 30px;
		gap: 30px;

		@include max(799px) {
			@include pad($color-neutral, left, after);
		}

		@include min(1400px) {
			padding-top: 80px;
			padding-left: 150px;
		}

		p {
			margin: 0;

			strong {
				font-size: rem(50px);
				font-weight: 800;
				color: $color-brand;
				display: block;
				line-height: 1;
				margin-bottom: 10px;
			}
		}
	}
}
